import React from "react"
import Layout from "../components/layout"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Service extends React.Component {
    render() {
        return (
            <Layout className="solid service-page">
                <HelmetDatoCms>
                    <title>Falve - Serwis</title>
                    <meta name="description"
                          content="Nasza firma w pierwszej kolejności stawia na jakość świadczonych przez nią usług, którą potwierdzają nasi Klienci. Dla nas nie liczą się tylko zdobyte referencje; przede wszystkim, zwracamy uwagę na to, by nasi Kontrahenci byli w pełni zadowoleni ze świadczonych przez nas usług serwisowych. Poczucie dobrze przeprowadzonej usługi cenimy najwyżej!"/>
                </HelmetDatoCms>
                <div className="service-wrapper">
                    <div className="text-center">
                        <h2>Serwis Falve</h2>
                    </div>
                    <div className="container">
                        <div className="item">
                            <span className="icon-boxes icon"></span>
                            <p>Sprzedaż części</p>
                        </div>
                        <div className="item">
                            <span className="icon-idea icon"></span>
                            <p>Doradztwo serwisowe</p>
                        </div>
                        <div className="item">
                            <span className="icon-maintenance icon"></span>
                            <p>Serwis</p>
                        </div>
                        <div className="item">
                            <span className="icon-settings icon"></span>
                            <p>Uruchomiania i utrzymanie ruchu</p>
                        </div>
                        <div className="item">
                            <span className="icon-cctv icon"></span>
                            <p>Monitoring</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="simple-text">
                        <h3>Dlaczego warto wybrać usługi serwisowe FALVE SERWIS?</h3>
                        <p>Nasza firma w pierwszej kolejności stawia na jakość świadczonych przez nią usług,
                            którą potwierdzają nasi Klienci. Dla nas nie liczą się tylko zdobyte referencje; przede
                            wszystkim, zwracamy uwagę na to, by nasi Kontrahenci byli w pełni zadowoleni ze
                            świadczonych przez nas usług serwisowych. Poczucie dobrze przeprowadzonej
                            usługi cenimy najwyżej!</p>
                        <p>Dlatego też, każdorazowo przykładamy wagę do najdrobniejszych szczegółów. Każdego Klienta
                            traktujemy w sposób indywidulany, bez względu na wartość wykonywanej usługi. Wszystko to
                            powoduje, że grono naszych Klientów z każdym dniem się powiększa.
                        </p>
                    </div>
                </div>
                <div className="container flex">
                    <div className="small text-center">
                        <span className="icon-boxes icon-title"></span>
                    </div>
                    <div className="simple-text large">
                        <h3>SERWIS NAJNOWSZEJ GENERACJI</h3>
                        <p>Do naszych napraw wykorzystujemy najlepszej jakości części zamienne, zwracamy uwagę na
                            detale. Świadczymy nasze usługi przy użyciu najnowszych technologii, jakie oferuje rynek
                            światowy.</p>
                        <p>Do każdego, przedłożonego nam zlecenia, podchodzimy skrupulatnie i indywidualnie, tak, by
                            wysłuchać i poznać dokładnie potrzeby naszego Klienta, a przy tym rozwiązać powstały
                            problem. To wszystko powoduje, że nasze niestandardowe rozwiązania znajdują zastosowanie w
                            różnych dziedzinach przemysłu.
                        </p>
                    </div>
                </div>
                <div className="container flex">
                    <div className="simple-text large">
                        <p>Nieustannie rozwijamy się dla Państwa, tak, by najlepiej sprostać oczekiwaniom rynku. W
                            szczególności przykładamy wagę do poszerzania świadczonych przez nas usług serwisowych,
                            m.in. w oparciu o własną produkcję części zamiennych najwyższej jakości oryginalnych ich
                            odpowiedników.
                        </p>
                        <p>Chcemy, by nasi Klienci darzyli nas 100% zaufaniem, dlatego podejmujemy szereg działań, by na
                            nie zasłużyć. Każdą, wykonaną naprawę lub przegląd potwierdzamy rzetelnym protokołem
                            serwisowym, uzupełnionym o dokumentację zdjęciową ze świadczonej usługi.
                        </p>
                        <p>Masz więcej pytań o FALVE SERWIS? <a href="/contact">Napisz do NAS</a>.</p>
                    </div>
                    <div className="small text-center">
                        <span className="icon-settings icon-title"></span>
                    </div>
                </div>
                <div className="container flex">
                    <div className="small text-center">
                        <span className="icon-idea icon-title"></span>
                    </div>
                    <div className="simple-text large">
                        <h3>SIŁA NASZYCH PRACOWNIKÓW</h3>
                        <p>Elementem łączącym wszystkie, wymienione wyżej składniki, a przy tym i niezbędnym, by nasz
                            FALVE SERVIS działał sprawnie – są nasi PRACOWNICY, którzy dokładają należytej staranności,
                            by każda, świadczona przez nas usługa serwisowa była przeprowadzona rzetelnie i na
                            najwyższym poziomie.</p>
                        <p>Doświadczenie i zaangażowanie każdego pracownika w przeprowadzane naprawy lub doradztwo jest
                            gwarancją najlepiej wykonanej usługi z dziedziny techniki pompowej, jaką FALVE SERVIS
                            oferuje. </p>
                    </div>
                </div>
                {/*<div className="container flex">*/}
                {/*    <div className="small text-center">*/}
                {/*        <span className="icon-maintenance icon-title"></span>*/}
                {/*    </div>*/}
                {/*    <div className="simple-text large">*/}
                {/*        <h3>Serwis</h3>*/}
                {/*        <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a*/}
                {/*            cursus. Sed id metus et libero mattis iaculis. Aliquam pretium viverra velit at accumsan. Ut*/}
                {/*            velit ligula, euismod a ultricies eu, placerat rutrum turpis. Aenean convallis orci vel*/}
                {/*            libero condimentum viverra. Maecenas egestas accumsan sapien. Cras laoreet neque a varius*/}
                {/*            fringilla. In quis magna laoreet, vestibulum sem vitae, eleifend diam. Aliquam sed metus*/}
                {/*            iaculis, imperdiet nisl non, elementum lectus. Quisque hendrerit luctus porttitor. In ut*/}
                {/*            feugiat lorem.Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget*/}
                {/*            sapien a cursus.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="container flex">*/}
                {/*    <div className="small text-center">*/}
                {/*        <span className="icon-cctv icon-title"></span>*/}
                {/*    </div>*/}
                {/*    <div className="simple-text large">*/}
                {/*        <h3>Serwis</h3>*/}
                {/*        <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a*/}
                {/*            cursus. Sed id metus et libero mattis iaculis. Aliquam pretium viverra velit at accumsan. Ut*/}
                {/*            velit ligula, euismod a ultricies eu, placerat rutrum turpis. Aenean convallis orci vel*/}
                {/*            libero condimentum viverra. Maecenas egestas accumsan sapien. Cras laoreet neque a varius*/}
                {/*            fringilla. In quis magna laoreet, vestibulum sem vitae, eleifend diam. Aliquam sed metus*/}
                {/*            iaculis, imperdiet nisl non, elementum lectus. Quisque hendrerit luctus porttitor. In ut*/}
                {/*            feugiat lorem.Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget*/}
                {/*            sapien a cursus.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="container">
                    <div className="simple-text text-center">
                        <a href="/contact" className="btn btn--yellow btn--lg">Napisz do nas</a>
                    </div>
                </div>
            </Layout>
        )
    }
}
